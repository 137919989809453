import React from "react";
import "./Login.css";
import { Authenticator, Greetings, withOAuth } from 'aws-amplify-react';


export default function Login(props) {

  function handleAuthStateChange(state) {
    // console.log("state changed to: " + state)
    if (state === 'signedIn') {
      props.userHasAuthenticated(true);
      props.history.push("/");
    } else if (state ==='signIn') {
      // signed out, currently redirect to login,
      // redirecting anywhere else prevents login since
      // this is triggered on the login page when first loaded also
      props.history.push("/login");
    }
  }

  return (
    <div className="Login">
      <Authenticator
        onStateChange={handleAuthStateChange}
        hide={
          [
            {/*Greetings*/}
          ]
        }
      >
      </Authenticator>
    </div>
  );
}
