import React from "react";
import "./Home.css";
import { Auth } from 'aws-amplify';

function getProfileData() {
  Auth.currentSession()
    .then(data => function(data){
      console.log(data.accessToken.jwtToken);
      var url = 'https://api.pfay.us/getPrivateProfileData?accessToken=' + data.accessToken.jwtToken;
      console.log(url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            // this.setState({
              // isLoaded: true,
              // items: result.items
            // });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log("request error")
          }
        )
    }(data))
    .catch(err => console.log(err));
}

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
        <button onClick={() => getProfileData()}>Get Profile Data</button>
      </div>
    </div>
  );
}
